###
===============================================

 Util

===============================================
###
class Util

	@map: ( value , inputMin , inputMax , outputMin , outputMax , clamp)->
		outVal = ((value - inputMin) / (inputMax - inputMin) * (outputMax - outputMin) + outputMin);
		if clamp
			if outputMax < outputMin
				if outVal < outputMax
					outVal = outputMax
				else if outVal > outputMin
					outVal = outputMin
			else
				if outVal > outputMax
					outVal = outputMax
				else if outVal < outputMin
					outVal = outputMin
		return outVal

	@getUrlVars: (value)->
		vars = {}
		max = 0
		hash = ""
		array = ""
		url = window.location.search
		hash  = url.slice(1).split('&')
		if hash[0] == ""
			return vars
		max = hash.length
		for i in [0...max]
			array = hash[i].split('=')
			#vars.push(array[0])
			vars[array[0]] = array[1]
		if value
			return vars[value]
		else
			return vars

	@getHashValue: ()->
		hash = window.location.hash
		return hash.replace('#','')

	@shuffle: (array)->
		m = array.length
		t
		i
		while (m)
			i = Math.floor(Math.random() * m--)
			t = array[m]
			array[m] = array[i]
			array[i] = t
		return array

	@no_scroll: =>
		document.addEventListener("mousewheel", @scroll_control, { passive: false })
		document.addEventListener("touchmove", @scroll_control, { passive: false })
		return

	@return_scroll: =>
		document.removeEventListener("mousewheel", @scroll_control, { passive: false })
		document.removeEventListener('touchmove', @scroll_control, { passive: false })
		return

	@scroll_control:(e)=>
		e.preventDefault()
		return


	constructor: ()->
		return

module.exports = Util

