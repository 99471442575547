import $ from "jquery"
import Browser from './lib/browser'
import Util from './lib/util'
import "slick-carousel"
import "lity"
import anime from "animejs";
import imagesLoaded from "imagesloaded"

class App
	constructor: ->
		@smoothScroll()
		@drawerTrigger = $('#js-drawer-trigger')
		@drawerContent = $('.js-drawer-content')
		@drawerNav()
		@Accordion()
		@fixNavi()
		@naviHeight()
		@pageTop()

		if $('#main').length
			@pageAnim()

		if $('#mainVisualSlide').length
			@mvSlider()
		
		if $('#topicsSlide').length 
			@topics_len = $('.topics__item').length-1
			@columnNum = Math.ceil(@topics_len/3.9) - 1
			@slickTopics()
		
		if $('#pickupArea').length
			@pickupArea()

		if $('#admission').length
			@admissionSlider()

		if $('#news, #media').length
			@viewCount()

		if $('#side_news').length
			@newsNavi()

		if $('#emergency').length
			@emergencyArea()
		
		if $('#js-searchOpen').length
			@searchBox()
		
		if $('#js-facnavi, #js-pagenavi').length
			@pageNavi()

		if $('#js-movieSlide').length
			@movieSlide()
		
		if $('#js-movieSlide2').length
			@movieSlide2()

		if $('#js-sateSlide').length
			@sateSlide()

		if $('#js-accessSlide').length
			@accessSlide()

		if $('#js-accessSlide2').length
			@accessSlide2()


	smoothScroll: =>
		urlHash = window.location.hash
		if urlHash
			$('body,html').stop().scrollTop(0)
			setTimeout ->
				scrollToAnker(urlHash)
			, 200
		# else
		# 	$(window).on 'beforeunload',()=>
		# 		$('body,html').scrollTop(0);

		$('a[href^="#"].js-scroll').on 'click',()->
			href = $(@).attr("href")
			hash = href
			scrollToAnker(hash)
			return false

		scrollToAnker = (hash)->
			target = hash
			os = 0
			if hash is '#top'
				target = 'body'
			if Browser.get().responsive == 'pc'
				os = 103
			else
				os = 100
			tgt_y = $(target).offset().top - os
			anime {
				targets: 'html, body'
				easing: 'easeOutExpo'
				duration: 800
				scrollTop: tgt_y
			}

	drawerNav: =>
		sethover = undefined
		setleave = undefined
		setnexthover = undefined
		targetOn = undefined
		targetOff = undefined
		nowActive = -1
		hoverClass = 'active'
		menuElement = $('.global-navi__list__item')
		hoverTime = 0
		$('.global-navi__inner').append('<div class="global-navi__line"></div>');
		menuElement.on
			'mouseenter': ->
				if $('#js-drawer-trigger').css('display') == 'none'
					targetOn = $(this)
					if nowActive == -1
						$('.global-navi__list__link').css 'opacity', 0.4
						targetOn.addClass hoverClass
						nowActive = menuElement.index(targetOn)
						if $('.js-acco').hasClass(hoverClass)
							$('.global-navi__list').addClass hoverClass
						$('.global-navi__line').addClass hoverClass
						lineX = targetOn.position().left
						lineW = targetOn.width()
						$('.global-navi__line').css 'left':lineX, 'width':lineW
						accoh = 0
						if targetOn.find('.global-navi__acco').length
							accoh = targetOn.find('.global-navi__acco').innerHeight()
						accoX = targetOn.position().left
						$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
						if targetOn.parents('.drawer-content__clone').length
							$tgt = '.drawer-content__clone .global-navi'
						if !targetOn.parents('.drawer-content__clone').length and $('body#top').length
							$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
						anime {
							targets: $tgt
							easing: 'easeOutExpo'
							duration: 800
							paddingBottom: accoh
						}
						targetOn.find('.global-navi__acco').css 'left', accoX
						return
					else
						if targetOn.hasClass(hoverClass)
							$('.global-navi__list__link').css 'opacity', 0.4
							if $('.js-acco').hasClass(hoverClass)
								$('.global-navi__list').addClass hoverClass
							$('.global-navi__line').addClass hoverClass
							lineX = targetOn.position().left
							lineW = targetOn.width()
							$('.global-navi__line').css 'left':lineX, 'width':lineW
							accoh = targetOn.find('.global-navi__acco').innerHeight()
							accoX = targetOn.position().left
							$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
							if targetOn.parents('.drawer-content__clone').length
								$tgt = '.drawer-content__clone .global-navi'
							if !targetOn.parents('.drawer-content__clone').length and $('body#top').length
								$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
							anime {
								targets: $tgt
								easing: 'easeOutExpo'
								duration: 800
								paddingBottom: accoh
							}
							targetOn.find('.global-navi__acco').css 'left', accoX
						else
							$('.global-navi__list__link').css 'opacity', 0.4
							menuElement.removeClass hoverClass
							targetOn.addClass hoverClass
							nowActive = menuElement.index(targetOn)
							if $('.js-acco').hasClass(hoverClass)
								$('.global-navi__list').addClass hoverClass
							$('.global-navi__line').addClass hoverClass
							lineX = targetOn.position().left
							lineW = targetOn.width()
							$('.global-navi__line').css 'left':lineX, 'width':lineW
							accoh = targetOn.find('.global-navi__acco').innerHeight()
							$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
							if targetOn.parents('.drawer-content__clone').length
								$tgt = '.drawer-content__clone .global-navi'
							if !targetOn.parents('.drawer-content__clone').length and $('body#top').length
								$tgt = '.drawer-content:not(.drawer-content__clone) .global-navi'
							anime {
								targets: $tgt
								easing: 'easeOutExpo'
								duration: 800
								paddingBottom: accoh
							}
							targetOn.find('.global-navi__acco').css 'left', accoX
					return
			'mouseleave': ->
				if $('#js-drawer-trigger').css('display') == 'none'
					mouseIsOverWorkaround = (what) ->
						temp = $(what).parent().find('active')
						temp.length == 1 and temp[0] == what
					targetOff = $(this)
					parent = targetOff
					if mouseIsOverWorkaround(parent[0])
						if targetOff.hasClass(hoverClass)
							$('.global-navi__list').removeClass hoverClass
							$('.global-navi__line').removeClass hoverClass
							clearTimeout setnexthover
					else
						targetOff.removeClass hoverClass
						nowActive = -1
						$('.global-navi__list__link').css 'opacity', 1
						$('.global-navi__list').removeClass hoverClass
						$('.global-navi__line').removeClass hoverClass
						accoh = 0
						$tgt = '.global-navi'
						
						if targetOff.parents('.drawer-content__clone').length
							$tgt = '.drawer-content__clone .global-navi'
						anime {
							targets: $tgt
							easing: 'easeOutExpo'
							duration: 800
							paddingBottom: accoh
						}
					return
		
		$('#js-header').prepend('<div class="header__bg" id="js-header-bg"></div>');
		$('#js-header-bg').on 'click',()=>
			@drawerTrigger.trigger('click')
		originY = 0
		@drawerTrigger.on 'click',()=>
			$('#global-nav').toggleClass('is-active')
			$('#js-header').toggleClass('is-active')
			@drawerTrigger.toggleClass('is-active')
			@drawerContent.toggleClass('is-active')
			$('body').toggleClass('is-noscroll')
			if @drawerTrigger.hasClass('is-active')
				originY = Browser.get().scrollY
				num = -1 * originY
				$('body,html').css 'cssText': 'top:' + num + 'px !important; height:' + window.innerHeight + 'px;'
				$('.drawer-content:not(.drawer-content__clone)').height(window.innerHeight-68).show()
				if Browser.get().responsive is 'sp'
					$('.drawer-content:not(.drawer-content__clone)').show()
					anime {
						targets: '.drawer-content'
						easing: 'easeOutExpo'
						duration: 500
						opacity: [0, 1]
					}
				else
					$('.drawer-content:not(.drawer-content__clone)').show()
					anime {
						targets: '.drawer-content'
						easing: 'easeOutExpo'
						duration: 500
						translateX: ['100%', 0]
					}
					$('#js-header-bg').show()
					anime {
						targets: '#js-header-bg'
						easing: 'easeOutExpo'
						duration: 250
						opacity: [0,0.5]
					}
				
				# Util.return_scroll()
			else
				# Util.no_scroll()
				$('body,html').attr 'style', ''
				$('body,html').scrollTop(originY)
				if Browser.get().responsive is 'sp'
					anime {
						targets: '.drawer-content'
						easing: 'easeOutExpo'
						duration: 500
						opacity: 0
						complete: =>
							$('.drawer-content:not(.drawer-content__clone)').hide()
					}
				else

					anime {
						targets: '.drawer-content'
						easing: 'easeOutExpo'
						duration: 500
						translateX: '100%'
						complete: =>
							$('.drawer-content:not(.drawer-content__clone)').hide()
					}
					anime {
						targets: '#js-header-bg'
						easing: 'easeOutExpo'
						duration: 500
						opacity: 0
						complete: =>
							$('#js-header-bg').hide()
					}
			return false


		resizeDrawer = () =>
			if $('#js-drawer-trigger').css('display') == 'block'
				$('.drawer-content__clone').hide()
			else if $('.drawer-content__clone').hasClass('is-fixed')
				$('.drawer-content__clone').show()
		window.addEventListener('resize', resizeDrawer);
	
	Accordion: =>
		$('.global-navi__list__trigger').on 'click',()->
			$(this).toggleClass('is-active')
			$(this).next('.global-navi__acco').slideToggle()

	fixNavi: =>
		$('.js-drawer-content').clone(true).appendTo('#js-header').addClass('drawer-content__clone');
		$('.drawer-content__clone .global-navi__inner').prepend('<a href="/" class="drawer-content__logo"><img src="/assets/images/common/logo_w.svg" alt=""></a>')
		$('.drawer-content__clone').hide()
		Browser.get().addScrollHandler =>
			st = Browser.get().scrollY
			# headerH = $('.js-drawer-content').height() + 105
			headerH = 245 + 105
			if $('#js-drawer-trigger').css('display') == 'none'
				if st > headerH
					if !$('.drawer-content__clone').hasClass('is-fixed')
						$('.drawer-content__clone').addClass('is-fixed').show()
						anime {
							targets: '.drawer-content__clone'
							easing: 'easeOutExpo'
							duration: 300
							translateY: 0
						}
						anime {
							targets: '.drawer-content__clone .global-navi'
							easing: 'easeOutExpo'
							duration: 10
							paddingBottom: 0
						}
				else
					if $('.drawer-content__clone').hasClass('is-fixed')
						$('.drawer-content__clone').removeClass('is-fixed')
						anime {
							targets: '.drawer-content__clone'
							easing: 'easeOutExpo'
							duration: 300
							translateY: -105
							complete: =>
								$('.drawer-content__clone').hide()
						}
			else
				$('.drawer-content__clone').hide()

	mvSlider: =>
		$('#mainVisualWrap').css 'opacity',0 
		mvW = $('#mainVisualWrap').width()
		mvH = mvW
		if Browser.get().responsive is 'pc'
			mvH = mvW*0.375
		$('#mainVisualSlide').height(mvH)
		
		$('#mainVisualSlide').on 'init', (event, slick, direction)=>
			imagesLoaded("#top", ->
				$('#mainVisualWrap').addClass('is-loaded')
				$('.global-navi').addClass('is-loaded')
				$('#js-target').addClass('is-loaded')
				anime {
					targets: '#mainVisualWrap'
					easing: 'easeOutExpo'
					duration: 800
					delay: 800
					opacity: [0,1]
					translateY: [100,0]
					complete: =>
						$('#js-spinner').hide()
						$('#mainVisualSlide').css 'height', 'auto'
				}
				anime {
					targets: '#js-spinner'
					easing: 'easeOutExpo'
					duration: 800
					delay: 500
					opacity: 0
					complete: =>
						$('#js-spinner').hide()
				}
			)

		$('#mainVisual').append('<svg id="js-spinner" class="mv__spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="mv__path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>')

		$('#mainVisualSlide').not('.slick-initialized').slick {
			autoplay: true
			autoplaySpeed: 4000
			arrows: false
			dots: true
			speed: 800
			dotsClass: 'mv__dots'
		}

		if Browser.get().responsive is 'pc' 
			$('.mv__dots li').append '<svg class="mv__dots__circle" height="28" width="28"><circle stroke-width="2" fill="transparent" r="13" cx="14" cy="14"></circle></svg>'

		if Browser.get().responsive is 'sp'
			$('.mv__dots li').append '<svg class="mv__dots__circle" height="20" width="20"><circle stroke-width="2" fill="transparent" r="9" cx="10" cy="10"></circle></svg>'

	slickTopics: =>
		if Browser.get().responsive is 'pc'
			$('#topicsSlide').not('.slick-initialized').slick {
				dots: true
				arrows: false
				slidesToShow: 3.9
				slidesToScroll: 4
				autoplay: false
				infinite: false
				dotsClass: 'topics__dots'
			}
		else if Browser.get().responsive is 'pc_s' 
			$('#topicsSlide').not('.slick-initialized').slick {
				dots: true
				arrows: false
				slidesToShow: 2.5
				slidesToScroll: 1
				autoplay: false
				infinite: false
				dotsClass: 'topics__dots'
			}
		else if Browser.get().responsive is 'sp'
			$('#topicsSlide').not('.slick-initialized').slick {
				dots: true
				arrows: false
				slidesToShow: 1.5
				slidesToScroll: 1
				autoplay: false
				infinite: false
				dotsClass: 'topics__dots'
			}

		$('#js-top-news__slick-prev').on 'click',()=>
			$('#topicsSlide').slick('slickPrev')
		
		$('#js-top-news__slick-next').on 'click',()=>
			$('#topicsSlide').slick('slickNext')
		
		$('#topicsSlide').on 'init reInit beforeChange', (event, slick, currentSlide, nextSlide)=>
			if Browser.get().responsive is 'pc'
				slideTotal = slick.slideCount
				slidePos = nextSlide/6*100
				$('#js-top-news__slick-bar').css('width': slidePos + '%')
				if nextSlide == 0
					$('#js-top-news__slick-prev').addClass('is-disable')
				else
					$('#js-top-news__slick-prev').removeClass('is-disable')
				if slick.slideCount - nextSlide < 3.9
					$('#js-top-news__slick-next').addClass('is-disable')
				else
					$('#js-top-news__slick-next').removeClass('is-disable')
		
		if Browser.get().responsive is 'pc'
			$('#js-top-news__slick-prev').addClass('is-disable')
		$('#topicsSlide').on 'afterChange', (event, slick, currentSlide)=>
			if Browser.get().responsive is 'pc'
				cs = slick.currentSlide / 3.9

	pickupArea: =>
		if Browser.get().responsive is 'pc'
			$('.pickup__item__link').append '<svg class="pickup__item__circle" height="38" width="38"><circle stroke-width="2" fill="transparent" r="18" cx="19" cy="19"></circle></svg>'
		if Browser.get().responsive is 'pc' 
			$('.pickup__banner__link').each (i) ->
				$(this).attr 'data-num', i + 1
				return
			$('.pickup__item').each (i) ->
				$(this).attr 'data-num', i + 1
				return
			$('.pickup__list .pickup__item').each (index, element) ->
				$(element).append '<div class="pickup__item__num">' + (index + 1).toString().padStart(2, '0') + '</div>'
				return

			if !$('.pickup__item').hasClass('active')
				$('.pickup__item[data-num="1"]').addClass 'active'
				$('.pickup__banner').attr 'data-current', 1
				$('.pickup__banner__link[data-num="1"]').addClass 'show'
			$('.pickup__item').hover (->
				$('.pickup__item').removeClass 'active'
				$('.pickup__banner__link').removeClass 'show'
				$(this).addClass 'active'
				active = $(this).data('num')
				$('.pickup__banner').attr 'data-current', active
				$('.pickup__banner[data-current="' + active + '"] .pickup__banner__link[data-num="' + active + '"]').addClass 'show'
				return
			)

		if Browser.get().responsive is 'pc_s' 
			$('#pickupSlide').slick
				dots: true
				arrows: false
				slidesToShow: 2.1
				slidesToScroll: 1
				autoplay: false
				infinite: false
				dotsClass: 'pickup__slide__dots'
			
			$('.pickup__slide .slick-slide').each (index, element) ->
				$(element).append '<div class="pickup__slide__num">' + (index + 1).toString().padStart(2, '0') + '</div>'
				return

		if Browser.get().responsive is 'sp' 
			$('#pickupSlide').slick
				dots: true
				arrows: false
				slidesToShow: 1.2
				slidesToScroll: 1
				autoplay: false
				infinite: false
				dotsClass: 'pickup__slide__dots'
			
			$('.pickup__slide .slick-slide').each (index, element) ->
				$(element).append '<div class="pickup__slide__num">' + (index + 1).toString().padStart(2, '0') + '</div>'
				return
	
	admissionSlider: =>
		$('#js-admission-list').slick
			dots: false
			arrows: false
			autoplay: true
			autoplaySpeed: 2000
			speed: 2000
			infinite: true
			fade: true
			cssEase: 'linear'

	viewCount: =>
		if Browser.get().responsive is 'sp' 
			$('.news__item:nth-of-type(n+4), .media__item:nth-of-type(n+4)').remove()
	
	newsNavi: =>
		if Browser.get().responsive is 'sp' 
			trigger = $('#side_news .sidetitle')
			trigger.click ->
				$(this).toggleClass('is-open')
				$(this).next('.sidelist').slideToggle()
				return

	emergencyArea: =>
		$('.emergency__close').on 'click',()=>
			$('#emergency').css('display', 'none')

	searchBox: =>
		open = $('#js-searchOpen')
		close = $('#js-searchClose')
		container = $('#headSearch')
		#開くボタンをクリックしたらモーダルを表示する
		open.on 'click', ->
			container.addClass 'is-open'
			false
		#閉じるボタンをクリックしたらモーダルを閉じる
		close.on 'click', ->
			container.removeClass 'is-open'
			return
		#モーダルの外側をクリックしたらモーダルを閉じる
		$(document).on 'click', (e) ->
			if !$(e.target).closest('.search-box').length
				container.removeClass 'is-open'
			return
		return

	pageNavi: =>
		if Browser.get().responsive is 'sp' 
			trigger = $('.facnavi__acco, .pagenavi__acco')
			trigger.click ->
				$(this).toggleClass('is-open')
				$(this).next('.facnavi-list, .pagenavi-list').slideToggle()
				return
			$(window).scroll ->
				kvH = $('.l-fackv, .l-pagekv').height() + 70
				if $(this).scrollTop() > kvH
					$('#js-facnavi, #js-pagenavi').addClass('is-fixed')
					$('.l-fackv, .l-pagekv').css('padding-bottom':'60px')
				else
					$('#js-facnavi, #js-pagenavi').removeClass('is-fixed')
					$('.l-fackv, .l-pagekv').css('padding-bottom':'0')
				return

	movieSlide: =>
		$('#js-movieSlide').slick
			slidesToShow: 2.7
			slidesToScroll: 2
			dots: true
			arrows: false
			autoplay: false
			infinite: false
			dotsClass: 'movie__list__dots'
			responsive: [
				breakpoint: 768
				settings: {
					slidesToShow: 1.05
					slidesToScroll: 1
				}
			]

	movieSlide2: =>
		$('#js-movieSlide2').slick
			slidesToShow: 2.7
			slidesToScroll: 2
			dots: true
			arrows: false
			autoplay: false
			infinite: false
			dotsClass: 'movie__list__dots'
			responsive: [
				breakpoint: 768
				settings: {
					slidesToShow: 1.05
					slidesToScroll: 1
				}
			]

	sateSlide: =>
		$('#js-sateSlide').slick
			slidesToShow: 1
			slidesToScroll: 1
			centerMode: true
			centerPadding: '6vw'
			dots: true
			arrows: false
			autoplay: true
			infinite: true
			dotsClass: 'sate-photo__dots'

	accessSlide: =>
		$('#js-accessSlide').slick
			slidesToShow: 2.33
			slidesToScroll: 2
			dots: true
			arrows: false
			autoplay: false
			infinite: false
			dotsClass: 'movie__list__dots'
			responsive: [
				breakpoint: 768
				settings: {
					slidesToShow: 1.05
					slidesToScroll: 1
				}
			]

	accessSlide2: =>
		$('#js-accessSlide2').slick
			slidesToShow: 2.33
			slidesToScroll: 2
			dots: true
			arrows: false
			autoplay: false
			infinite: false
			dotsClass: 'movie__list__dots'
			responsive: [
				breakpoint: 768
				settings: {
					slidesToShow: 1.05
					slidesToScroll: 1
				}
			]

	naviHeight: =>
		setFillHeight = () =>
			vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', vh + 'px')

		# 画面のサイズ変動があった時に高さを再計算する
		window.addEventListener('resize', setFillHeight);

		# 初期化
		setFillHeight();

	pageTop: =>
		$(window).scroll ->
			if $(this).scrollTop() > 300
				$('#pagetop').fadeIn()
			else
				$('#pagetop').fadeOut()
			return
		$('#pagetop').click ->
			$('html, body').animate { scrollTop: 0 }, 'slow'
			console.log('clickthis')
			false


module.exports = App

$(->
	new App
)