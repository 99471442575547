###
===============================================

	Browser

===============================================
###
class Browser

	constructor: ()->
		@PC_S_WIDTH = 1300
		@SP_MAX_WIDTH = 768
		@TB_MIN_WIDTH = 769
		@TB_MAX_WIDTH = 1024

		@isIE = false
		@isIE6 = false
		@isIE7 = false
		@isIE8 = false
		@isIE9 = false
		@isIE11 = false
		@isFF = false
		@isFF35 = false
		@isOpera = false
		@isChrome = false
		@isSafari = false
		@isiPhone = false
		@isiPad = false
		@isiPod = false
		@isAndroid = false

		@isCanvas = false
		@isTransform = false
		@isTransition = false
		@isSp = false
		@isTouch = window.hasOwnProperty('ontouchstart')

		@isWindows = false
		@isMac = false
		@isWindowFocus = true

		@scrollX = 0
		@scrollY = 0
		@scrollYPercent = 0
		@windowWidth = 0
		@windowHeight = 0
		@windowWidthHalf = 0
		@windowHeightHalf = 0
		@documentWidth = 0
		@documentHeight = 0
		@scrollRate = 0

		@resizeFunction = []
		@responsivePCTBSPFunction = []
		@responsiveFunction = []
		@scrollFunction = []
		@requestAnimationFrameFunction = []

		@responsivePCTBSP = 'pc'
		@responsive = 'pc'
		@transitionend = 'oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend'
		@animationend = 'webkitAnimationEnd animationend'
		if 'onwheel' in document
			@scrollEvent = 'wheel'
		else
			if 'onmousewheel' in document
				@scrollEvent = 'mousewheel'
			else
				@scrollEvent = 'DOMMouseScroll'

		webglcheck = ->
			try
				canvas = document.createElement( 'canvas' )
				return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) )
			catch e
				return false

		@webgl = webglcheck()
		@initUA()

		window.addEventListener('scroll', @_scrollHandler.bind(@) , false)
		@_scrollHandler()

		window.addEventListener('resize', @_resizeHandler.bind(@) , false)
		@_resizeHandler()

		window.addEventListener 'focus',()=>
			@isWindowFocus = true
		window.addEventListener 'blur',()=>
			@isWindowFocus = false


	###
	----------------------------------------
	PUBLIC METHOD
	----------------------------------------
	###
	initUA: ()->
		userAgent = window.navigator.userAgent.toLowerCase()
		appVersion = window.navigator.appVersion.toLowerCase()

		if userAgent.indexOf("msie") > -1
			@isIE = true
			if appVersion.indexOf("msie 6.0") > -1
				@isIE6 = true
			else if appVersion.indexOf("msie 7.0") > -1
				@isIE7 = true
			else if appVersion.indexOf("msie 8.0") > -1
				@isIE8 = true
			else if appVersion.indexOf("msie 9.0") > -1
				@isIE9 = true
			else if userAgent.indexOf("trident") > -1
				@isIE = true
		else if userAgent.indexOf("trident") > -1
			@isIE11 = true
			@isIE = true
		else if userAgent.indexOf("firefox") > -1
			@isFF = true
			version = parseFloat(userAgent.match(/firefox\/(\d+\.\d+)/)[1])
			@isFF35 = version >= 3.5
		else if userAgent.indexOf("iphone") > -1
			@isiPhone = true
		else if userAgent.indexOf("ipod") > -1
			@isiPod = true
		else if userAgent.indexOf("ipad") > -1
			@isiPad = true
		else if userAgent.indexOf("android") > -1
			@isAndroid = true
		else if userAgent.indexOf("opera") > -1
			@isOpera = true
		else if userAgent.indexOf("chrome") > -1
			@isChrome = true
		else if userAgent.indexOf("safari") > -1
			@isSafari = true

		if userAgent.indexOf("mac") > -1
			@isMac = true
		else if userAgent.indexOf("win") > -1
			@isWindows = true

		@isSp = (@isiPhone || @isiPod || @isAndroid)
		return

	addResizeHandler: (func , scope)->
		if typeof func == 'function'
			@resizeFunction.push {fn:func , scope:scope}
		return

	removeResizeHandler: (func)->
		if typeof func == 'function'
			for v,i in @resizeFunction
				f = @resizeFunction[i].fn
				if f == func
					@resizeFunction.splice(i,1)
		return

	addResponsiveHandler: (func , scope)->
		if typeof func == 'function'
			@responsiveFunction.push {fn:func , scope:scope}
		return

	removeResponsiveHandler: (func)->
		if typeof func == 'function'
			for v,i in @responsiveFunction
				f = @responsiveFunction[i].fn
				if f == func
					@responsiveFunction.splice(i,1)
		return

	addResponsivePCTBSPHandler: (func , scope)->
		if typeof func == 'function'
			@responsivePCTBSPunction.push {fn:func , scope:scope}
		return

	removeResponsivePCTBSPHandler: (func)->
		if typeof func == 'function'
			for v,i in @responsivePCTBSPunction
				f = @responsivePCTBSPunction[i].fn
				if f == func
					@responsivePCTBSPunction.splice(i,1)
		return

	addScrollHandler: (func , scope)->
		if typeof func == 'function'
			@scrollFunction.push {fn:func , scope:scope}
		return

	removeScrollHandler: (func)->
		if typeof func == 'function'
			for v,i in @scrollFunction
				f = @scrollFunction[i].fn
				if f == func
					@scrollFunction.splice(i,1)
		return

	resize: ()->
		@_resizeHandler()
		return

	setViewportiPad: ()->
		if !@isiPad
			return
		metalist = document.getElementsByTagName('meta')
		for v,i in metalist
			name = metalist[i].getAttribute('name')
			if name && name.toLowerCase() is 'viewport'
				metalist[i].setAttribute('content', 'width=1100,user-scalable=no')
				break
		@resize()
		return

	###
	----------------------------------------
	PRIVATE METHOD
	----------------------------------------
	###
	_getScrollPosition: ()->
		obj = new Object()
		obj.x = document.documentElement.scrollLeft || document.body.scrollLeft
		obj.y = document.documentElement.scrollTop || document.body.scrollTop
		return obj

	_getScreenSize: ()->
		obj = new Object()
		#if !@isSafari && !@isOpera
		#	obj.width = document.documentElement.clientWidth || document.body.clientWidth || document.body.scrollWidth
		#	obj.height = document.documentElement.clientHeight || document.body.clientHeight || document.body.scrollHeight
			#obj.width = document.documentElement.clientWidth || document.body.clientWidth
			#obj.height = document.documentElement.clientHeight || document.body.clientHeight
		#else
		#	obj.width = window.innerWidth
		#	obj.height = window.innerHeight
		obj.width = window.innerWidth
		obj.height = window.innerHeight
		obj.widthHalf = parseInt((obj.width)/2)
		obj.heightHalf = parseInt((obj.height)/2)
		return obj

	_getDocumentSize: ()->
		obj = new Object()
		obj.width = document.documentElement.scrollWidth || document.body.scrollWidth
		obj.height = document.documentElement.scrollHeight || document.body.scrollHeight
		return obj

	_resizeHandler: ()->
		screenSize = @_getScreenSize()
		@windowWidth = screenSize.width
		@windowHeight = screenSize.height
		@windowWidthHalf = screenSize.widthHalf
		@windowHeightHalf = screenSize.heightHalf
		documentSize = @_getDocumentSize()
		@documentWidth = documentSize.width
		@documentHeight = documentSize.height
		for v,i in @resizeFunction
			@resizeFunction[i].fn.apply( @resizeFunction[i].scope )

		#PC TB SP
		SP_MAX_WIDTH = @SP_MAX_WIDTH
		TB_MIN_WIDTH = @TB_MIN_WIDTH
		TB_MAX_WIDTH = @TB_MAX_WIDTH
		PC_S_WIDTH = @PC_S_WIDTH

		# if window.matchMedia && window.matchMedia("(max-width:#{SP_MAX_WIDTH}px)").matches
		# 	if @responsivePCTBSP != 'sp'
		# 		@responsivePCTBSP = 'sp'
		# 		for v,i in @responsivePCTBSPFunction
		# 			@responsivePCTBSPFunction[i].fn.apply( @responsivePCTBSPFunction[i].scope )
		# else if window.matchMedia && window.matchMedia("(min-width: #{TB_MIN_WIDTH}px) and (max-width: #{@TB_MAX_WIDTH}px)").matches
		# 	if @responsivePCTBSP != 'tb'
		# 		@responsivePCTBSP = 'tb'
		# 		for v,i in @responsivePCTBSPFunction
		# 			@responsivePCTBSPFunction[i].fn.apply( @responsivePCTBSPFunction[i].scope )
		# else
		# 	if @responsivePCTBSP != 'pc'
		# 		@responsivePCTBSP = 'pc'
		# 		for v,i in @responsivePCTBSPFunction
		# 			@responsivePCTBSPFunction[i].fn.apply( @responsivePCTBSPFunction[i].scope )

		# PC SP
		if window.matchMedia && window.matchMedia("(max-width:#{SP_MAX_WIDTH}px)").matches
			if @responsive != 'sp'
				@responsive = 'sp'
				for v,i in @responsiveFunction
					@responsiveFunction[i].fn.apply( @responsiveFunction[i].scope )
		else if window.matchMedia && window.matchMedia("(max-width:#{PC_S_WIDTH}px)").matches
			if @responsive != 'pc_s'
				@responsive = 'pc_s'
				for v,i in @responsiveFunction
					@responsiveFunction[i].fn.apply( @responsiveFunction[i].scope )
		else
			if @responsive != 'pc'
				@responsive = 'pc'
				for v,i in @responsiveFunction
					@responsiveFunction[i].fn.apply( @responsiveFunction[i].scope )

		return

	_scrollHandler: ()->
		scrollPosition = @_getScrollPosition()
		@scrollX = scrollPosition.x
		@scrollY = scrollPosition.y
		if @windowWidth != 0
			@scrollYPercent = @scrollY / @windowWidth
		else
			@scrollYPercent = 0
		@scrollRate = @scrollY / (@documentHeight - @windowHeight)

		for v,i in @scrollFunction
			@scrollFunction[i].fn.apply( @scrollFunction[i].scope )
		return

instances = null
module.exports = {
	get: () =>
		return instances if instances?
		instances = new Browser
		return instances
}

